<template>
  <div>
    <b-card>
      <div>
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="openAddPage"
                  class="ml-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-right">新增</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card
              v-show="advanced_search_modal">
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="发票类型:"
                    label-for="invoice_type"
                    label-size="sm"
                >
                  <v-select
                      id="invoice_type"
                      :options="getCodeOptions('invoice_type')"
                      :clearable="true"
                      v-model = "condition.invoice_type"
                      class="select-size-sm"
                      placeholder="选择发票类型"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="发票形式:"
                    label-for="invoice_material"
                    label-size="sm"
                >
                  <v-select
                      id="invoice_material"
                      :options="getCodeOptions('invoice_material')"
                      :clearable="true"
                      v-model = "condition.invoice_material"
                      class="select-size-sm"
                      placeholder="选择发票形式"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="购买方名称:"
                    label-for="buyer_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="buyer_name"
                      v-model="condition.buyer_name"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" cols="12">
                <xy-input-button label="开票日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['make_date_str'])"
                                 :params="['make_date_str']"
                                 :value="condition.make_date_str"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="状态:"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('purchase_invoice_status')"
                      :clearable="true"
                      v-model = "condition.status"
                      class="select-size-sm"
                      placeholder="选择发票状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="销售方名称:"
                    label-for="sell_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="sell_name"
                      v-model="condition.sell_name"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </div>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="invoice_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

<!--        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="1" variant="primary">
              <span><strong>合计</strong></span>
            </b-td>
            <b-td :colspan="4" variant="primary">
              <span><strong></strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{totalAmount}}</strong></span>
            </b-td>
            <b-td :colspan="13" variant="primary">
              <span><strong></strong></span>
            </b-td>
          </b-tr>
        </template>-->
        <!-- Columns -->
        <template #cell(invoice_type)="data">
          {{ getCodeLabel('invoice_type', data.item.invoice_type) }}
        </template>
        <template #cell(invoice_code)="data">
          {{ data.item.invoice_code }}
        </template>
        <template #cell(invoice_no)="data">
          {{ data.item.invoice_no }}
        </template>
        <template #cell(buyer_name)="data">
          {{ data.item.buyer_name }}
        </template>
        <template #cell(sell_name)="data">
          {{ data.item.sell_name }}
        </template>
        <template #cell(amount)="data">
          {{ data.item.amount }}
        </template>
        <template #cell(tax_amount)="data">
          {{ data.item.tax_amount }}
        </template>
        <template #cell(total)="data">
          {{ data.item.total }}
        </template>
        <template #cell(make_date_str)="data">
          {{ data.item.make_date_str }}
        </template>
        <template #cell(invoice_material)="data">
          {{ getCodeLabel('invoice_material', data.item.invoice_material) }}
        </template>
        <template #cell(status)="data">
          {{ getCodeLabel('purchase_invoice_status', data.item.status) }}
        </template>
        <template #cell(create_time)="data">
          {{ toDate(data.item.create_time) }}
        </template>
        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>
        <template #cell(source)="data">
          {{ data.item.source == 1?'手工录入':'发票申请' }}
        </template>
        <template #cell(attachments)="data">
          <div v-show="data.item.attachments !== undefined&&data.item.attachments !== '' ">
            <feather-icon icon="EyeIcon" @click="showAttachments(data.item.attachments)"/>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="data.item.status == 1" @click="openEditPage(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.status == 1" @click="voidItem(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">作废</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.status == 1" @click="changeStatus(data.item,2)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.status == 2 ||data.item.status == 3 || data.item.status == 4" @click="openViewPage(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">查看</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.status == 2&&data.item.source!=2" @click="createStatement(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">创建结算单</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.status == 2" @click="changeStatus(data.item,1)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
      <!--/ form -->
      <b-modal
          id="loadingModal"
          centered
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          ref="loadingModal"
      >
        <p class="my-4">正在处理，请勿关闭浏览器!</p>
      </b-modal>
<!--      <b-modal
          id="modal-add"
          ok-title="确认"
          @ok="saveInfo"
          cancel-title="取消"
          centered
          size="lg"
          :title="'新增进项发票'"
          ref="addModal"
      >
        <purchase-invoice-edit
            ref="addReceive" :invoice_id="invoice_id" :readonlyFlag="readonlyFlag">
        </purchase-invoice-edit>
      </b-modal>-->
      <b-modal
          id="modal-attachments"
          centered
          ok-only
          ok-title="确认"
          size="lg"
          title="查看附件"
          ref="attachModal"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments_ids"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload :theme="'files'"
                                 :attachment_id="'attachments_ch'"
                                 :id="attachmentsStr"
                                 :object_type="'purchaseInvoice'"
                                 :object_id="0"
                                 :readonly="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem,BFormRadio, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { toDate,avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions,isEmpty } from '@core/utils/filter'
import purchaseInvoiceListStore from "@/views/apps/purchaseinvoice/purchaseInvoiceListStore";
// import purchaseInvoiceEdit from "@/views/apps/purchaseinvoice/PurchaseInvoiceEdit.vue";
import purchaseInvoiceList from './purchaseInvoiceList.js'
import axios from "@/libs/axios";
import XyInputButton from "@/views/components/xy/XyInputButton";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {second} from "@core/utils/dayjs/utils";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormRadio,
    BPagination,
    vSelect,
    XyInputButton,
    AttachmentUpload,
    // purchaseInvoiceEdit,
  },
  data() {
    return {
      advanced_search_modal: false,
      // invoice_id:0,
      readonlyFlag:false,
      attachmentsStr: "",
      status:0,
    }
  },
  methods: {
    searchByCondition(){
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('purchaseInvoiceList/updateCondition', this.state.condition)
      this.refetchData()
    },
    openAddPage: function () {
      this.$router.push({name: 'apps-purchase-invoice-edit', query: { id: 0,readonlyFlag:false }});
      /*this.invoice_id = 0;
      this.$refs['addModal'].show();*/
    },

    openEditPage: function (item) {
      this.$router.push({name: 'apps-purchase-invoice-edit', query: { id: item.invoice_id,readonlyFlag:false }});
      // this.invoice_id = item.invoice_id;
      // this.$refs['addModal'].show();
    },

    openViewPage: function (item) {
      this.$router.push({name: 'apps-purchase-invoice-edit', query: { id: item.invoice_id,readonlyFlag:true }});
      /*this.invoice_id = item.invoice_id;
      this.readonlyFlag = true;
      this.$refs['addModal'].show();*/
    },
    showAttachments: function (item) {
      this.attachmentsStr = item
      this.$refs['attachModal'].show()
    },
    saveInfo: function (bvModalEvt){
      let saveInfo = this.$refs.addReceive.getSaveInfo();
      axios.post('/api/storecashout/checkExists', objcheck).then(res => {

        saveInfo.get_date = second(saveInfo.get_date);
        saveInfo.ext = null;
        if (res.data.data.ext.exists == true) {
          if (confirm("该日期，已有提现记录，是否继续添加?")){
            store
                .dispatch('purchaseInvoiceList/save', saveInfo)
                .then(res => {
                  if (res.data.code === 0) {
                    this.refetchData()
                  } else {
                    alert(res.data.data)
                  }
                })
          }
        } else {
          store
              .dispatch('purchaseInvoiceList/save', saveInfo)
              .then(res => {
                if (res.data.code === 0) {
                  this.refetchData()
                } else {
                  alert(res.data.data)
                }
              })
        }
        this.$refs['loadingModal'].hide()
      })
    },
    changeStatus: function (item,status) {
      this.$refs['loadingModal'].show()
      store
          .dispatch('purchaseInvoiceList/changeStatus', {
            id: item.invoice_id,
            status: status,
          })
          .then(res => {
            this.$refs['loadingModal'].hide()
            if (res.data.code === 0) {
              this.refetchData()
            } else {
              alert(res.data.data)
            }
          })
    },
    voidItem: function (item) {
      this.$swal({
        title: "确定作废吗",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.$refs['loadingModal'].show()
          store
              .dispatch('purchaseInvoiceList/voidItem', {
                id: item.invoice_id,
              })
              .then(res => {
                this.$refs['loadingModal'].hide()
                if (res.data.code === 0) {
                  //弹窗
                  this.$swal({
                    icon: 'success',
                    title: '已作废!',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  this.refetchData()
                } else {
                  alert(res.data.data)
                }
              })
        }
      })
    },

    createStatement: function (item) {
      store
          .dispatch('purchaseInvoiceList/createStatement', {
            id: item.invoice_id,
          })
          .then(res => {
            if (res.data.code === 0) {
              let data = res.data.data;
              this.$router.push({name: 'apps-purchase-invoice-statement-edit', query: {
                invoice_id:data.invoice_id,
                company_id:data.company_id,
                company_name:data.company_name,
                supplier_id:data.supplier_id,
                supplier_name:data.supplier_name,
                }
              });
            } else {
              alert(res.data.data)
            }
          })
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    const toast = useToast()

    const state = reactive({
      condition: {},
    })
    // Register module
    if (!store.hasModule('purchaseInvoiceList')) store.registerModule('purchaseInvoiceList', purchaseInvoiceListStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseInvoiceList')) store.unregisterModule('purchaseInvoiceList')
    })
    onMounted(() => {
      state.condition = store.getters['purchaseInvoiceList/getCondition']
    })

    const advanced_search = function () {
      this.advanced_search_modal = !this.advanced_search_modal;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const initPage = function (){
    }

    const {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,


    } = purchaseInvoiceList(
        {}
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      initPage,
      fromChildren,
      advanced_search,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      toDate,
      getCodeColor,
      getCodeOptions,
      isEmpty,
    }
  },
  created() {
    this.initPage()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
