import axios from '@axios'

export default {
  namespaced: true,
  state: {
    condition:{
      // status:'1',
    },
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      state.condition = condition
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinvoice/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinvoice/voidItem', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinvoice/changeStatus', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    initEditPage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinvoice/initEditPage', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    analyseInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: { "Content-Type": "multipart/form-data" }
        }
        axios
            .post('/api/purchaseinvoice/analyseInvoice', params, config)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinvoice/save', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchCompanyRelation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/purchaseinvoice/searchCompanyRelation', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createStatement(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/purchaseinvoice/createStatement', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
