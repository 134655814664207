import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function purchaseInvoiceList(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'invoice_type', label: '发票类型' , sortable: true},
    { key: 'invoice_code', label: '发票代码' , sortable: true},
    { key: 'invoice_no', label: '发票号码' , sortable: true},
    { key: 'buyer_name', label: '购买方名称' , sortable: true},
    { key: 'sell_name', label: '销售方名称' , sortable: true},
    { key: 'amount', label: '金额' , sortable: true},
    { key: 'tax_amount', label: '税额' , sortable: true},
    { key: 'total', label: '价税合计' , sortable: true},
    { key: 'make_date_str', label: '开票日期' , sortable: true},
    { key: 'invoice_material', label: '发票形式' , sortable: true},
    { key: 'source', label: '发票来源' , sortable: true},
    { key: 'status', label: '状态' , sortable: true},
    { key: 'create_time', label: '创建时间' , sortable: true},
    { key: 'creator', label: '创建人' , sortable: true},
    { key: 'attachments', label: '附件' },
    { key: 'actions', label: '操作'},
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref(" invoice_id desc ")
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['purchaseInvoiceList/getCondition']}
    store
        .dispatch('purchaseInvoiceList/search', {
          invoice_type: condition.invoice_type,
          invoice_material: condition.invoice_material,
          buyer_name: condition.buyer_name,
          status: condition.status,
          make_date_str: condition.make_date_str,
          sell_name: condition.sell_name,

          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const list = response.data.data.ext.list
          listTotals.value = response.data.data.ext.totals
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '进项发票列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,

  }
}
